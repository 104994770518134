import { Card } from 'react-bootstrap';

function CardRadio({ children, onClick, isEqual }) {
  return (
    <a href="#" onClick={onClick}>
      <Card className='card' style={{ height: '200px', width: '200px' }} ref={element => {
        if (element && isEqual() == true) element.style.setProperty('border', 'solid', 'important');
        else if (element) element.style.setProperty('border', 'none', 'important');
      }}>
        <Card.Body>
          {children}
        </Card.Body>
      </Card></a>)
    ;
}

export default CardRadio;
