import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from './assets/logo.png';
import './NavbarContainer.css';
import React, { useEffect, useState } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ShimmerCircularImage } from "react-shimmer-effects";
import HomeRail from './home/HomeRail';

function NavbarContainer({isSmallScreen}) {

  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [profile, setProfile] = useState<{ name: string, picture_url: string } | null>(null);
  const jwtToken = Cookies.get('jwtToken');
  const isAuthenticated = jwtToken != null;

  useEffect(() => {
    if (isAuthenticated) {
      setIsProfileLoading(true);
      setProfile(null);
      axios.get('/profile', {})
        .then((response) => {
          setProfile({ name: response.data.name, picture_url: response.data.picture_url });
          setIsProfileLoading(false);
        })
        .catch((error) => {
          setIsProfileLoading(false);
          console.log(error);
        });
    }
  }, [isAuthenticated]);

  let profileComp: any = null;
  if (isAuthenticated) {
    if (isProfileLoading) {
      profileComp = (
        <div style={{ marginRight: '34px' }}>
          <div style={{ height: '60px', width: isSmallScreen ? undefined : '160px', display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
            <ShimmerCircularImage size={32} />
          </div>
        </div>
      );
    } else {
      profileComp = (
        <div style={{ marginRight: '34px' }}>
          <Dropdown>
            <Dropdown.Toggle as={React.forwardRef<HTMLAnchorElement>((props, ref) => {
              const onClick = props['onClick'];
              return (
                <div style={{ height: '60px', width: isSmallScreen ? undefined : '160px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <a href=""
                    ref={ref}
                    onClick={(e) => {
                      e.preventDefault();
                      return onClick(e);
                    }}>
                    <img
                      style={{ borderRadius: '50%' }}
                      src={profile?.picture_url}
                      width={32}
                      height={32}
                      alt="profile icon"
                    />
                  </a>
                </div>
              );
            })} id="dropdown-custom-components">
              Icon Toggle
            </Dropdown.Toggle>

            <Dropdown.Menu style={{right: '0px', left: 'auto'}}>
              <Dropdown.Header>{profile?.name}</Dropdown.Header>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="1" onClick={() => {
                Cookies.remove('jwtToken');
                window.location.href = '/login';
              }}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }
  }

  return (
    <Navbar expand="lg" className='justify-content-between'>
      <Navbar.Brand href="/">
        <img
          src={logo}
          width={124}
          height={30}
          alt="RoasHunt logo"
          className='logo'
        />
      </Navbar.Brand>
      {!isSmallScreen && isAuthenticated && <HomeRail />}
      {profileComp}
    </Navbar>
  );
}

export default NavbarContainer;
