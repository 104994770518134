import { ReactNode } from "react";
import { AdCardType } from "./CollectionBody";
import AdVideoAttachment from "./attachments/AdVideoAttachment";
import AdImageAttachment from "./attachments/AdImageAttachment";
import AdCarouselAttachment from "./attachments/AdCarouselAttachment";
import AdMultiImagesAttachment from "./attachments/AdMultiImagesAttachment";

function AdAttachment(card: AdCardType) {

  let component: ReactNode | null = null;
  if (card.asset == null) {
    return null;
  }
  switch (card.asset.format) {
    case "video":
      component = <AdVideoAttachment {...card} />;
      break;
    case "image":
      component = <AdImageAttachment {...card} />;
      break;
    case "carousel":
      component = <AdCarouselAttachment {...card} />;
      break;
    case "dpa":
      component = <AdCarouselAttachment {...card} />;
      break;
    case "multi_images":
      component = <AdMultiImagesAttachment {...card} />;
  }

  return component;
}

export default AdAttachment;
