import React from "react";
import JSZip from 'jszip';

export type AdDownloadData = {
  media_urls: string[],
  format: string
};

export type AdDownloadContextData = {
  adDownloadClosure: {
    setAdDownloadData: (data: AdDownloadData | null) => void;
    downloadAdMedia: () => Promise<void>;
    pushMedia: (url: string, idx: number) => void;
  }
}

export const AdDownloadContext = React.createContext<{
  data: AdDownloadContextData | null,
}>({ data: null });

function getFileNameFromUrl(url: string): string | null {
  // Use the URL constructor to parse the URL
  const urlObject = new URL(url);

  // Access the pathname and split it by '/'
  const pathSegments = urlObject.pathname.split('/');

  // Get the last segment, which should be the file name
  const fileName = pathSegments[pathSegments.length - 1];

  return fileName || null;
}

async function dowloadMedia(urls) {
  try {
    const zip = new JSZip();

    // Fetch each video file and add it to the zip
    for (const url of urls) {
      const response = await fetch(url, {mode: 'cors'});
      const fileName = getFileNameFromUrl(url);
      if (response.ok && fileName != null) {
        const videoBlob = await response.blob();
        zip.file(fileName, videoBlob);
      } else {
        console.error(`Failed to fetch ${fileName}. Status: ${response.status}`);
      }
    }

     // Generate the zip file
     const zipBlob = await zip.generateAsync({ type: 'blob' });

     // Create a link element
     const link = document.createElement('a');
 
     // Create a URL for the Blob and set it as the href of the link
     link.href = window.URL.createObjectURL(zipBlob);
 
     // Set the download attribute with the desired zip file name
     link.download = 'roas_hunt_media_download.zip';
 
     // Append the link to the document
     document.body.appendChild(link);
 
     // Trigger a click on the link to start the download
     link.click();
 
     // Remove the link from the document
     document.body.removeChild(link);
  } catch (error) {
    console.error('Error during video download and zip:', error);
  }
}

export const adDownloadInit = () => {

  let adDownloadData: AdDownloadData | null = null;

  const setAdDownloadData = (data: AdDownloadData | null) => {
    adDownloadData = data;
  }

  const pushMedia = (url, idx) => {
    if (adDownloadData != null) {
      adDownloadData.media_urls[idx] = url;
    }
  }

  const downloadAdMedia = async () => {
    console.log(adDownloadData);
    if (adDownloadData == null) {
      return;
    }
  
    const urls = adDownloadData.media_urls.filter(Boolean);
    if (urls.length === 0) {
      return;
    }

    await dowloadMedia(urls);
  }

  return {
    setAdDownloadData,
    downloadAdMedia,
    pushMedia
  };
}