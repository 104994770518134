import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Card, Form, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import './css_additions/card.css';
import { GoogleLogin } from '@react-oauth/google';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-M323HH35GG"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Login() {

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [validationMsg, setValidationMsg] = useState('');

    const [isForgotPasswordState, setIsForgotPasswordState] = useState(false);
    const [forgotMessageResponse, setForgotMessageResponse] = useState(null);
    const forgotPasswordSubmit = (event) => {
        event.preventDefault();
        const { email } = formData;
        const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        if (!isValidEmail) {
            setValidationMsg('Invalid email format.');
            return;
        }

        axios.post('/login/forgot', { email })
            .then((response) => {
                setForgotMessageResponse(response.data.message);
            })
            .catch((error) => {
                setValidationMsg(error.response.data.message);
            });
    }

    const loginResponseHandler = (response) => {
        const data = response.data;
        const expires = new Date();
        expires.setDate(expires.getDate() + 7);
        document.cookie = "jwtToken=" + data.token + ";expires=" + expires.toUTCString() + ";path=/";
        if (data.hasOwnProperty('onboarding_eligible') && data['onboarding_eligible'] === true) {
            window.location.href = '/onboarding'
        } else {
            window.location.href = '/';
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { email, password } = formData;
        const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        if (!isValidEmail) {
            setValidationMsg('Invalid email format.');
            return;
        }

        axios.post('/login', formData)
            .then(loginResponseHandler)
            .catch((error) => {
                setValidationMsg(error.response.data.message);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const navigate = useNavigate();
    const { state } = useLocation();

    if (isForgotPasswordState) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stack style={{ width: '18rem', marginTop: '100px' }}>
                    {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
                        {validationMsg}
                    </Alert>}
                    {forgotMessageResponse != null && <Alert key={'success'} variant={'success'}>
                        {forgotMessageResponse}
                    </Alert>}
                    <Card className='card'>
                        <Card.Body>
                            <Card.Title>Forgot Password</Card.Title>
                            <Form onSubmit={forgotPasswordSubmit} style={{ marginTop: '16px' }}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" name='email' onChange={handleChange} />
                                </Form.Group>
                                <div style={{ marginTop: '30px' }}>
                                    <Button variant="primary" type="submit">
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Stack>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ width: '18rem', marginTop: '100px' }}>
                {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
                    {validationMsg}
                </Alert>}
                {state?.isRegister === true && <Alert key={'success'} variant={'success'}>
                    Thank you for registering!
                </Alert>}
                <Card className='card'>
                    <Card.Body>
                        <Card.Title>Log In</Card.Title>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '1px',
                            borderBottomColor: '#b8b8b8'
                        }}>
                            <GoogleLogin
                                width={248}
                                onSuccess={credentialResponse => {
                                    ReactGA.gtag('get', TRACKING_ID, 'client_id', (clientID) => {
                                        axios.post('/login', { g_token: credentialResponse.credential, g_client_id: clientID })
                                        .then(loginResponseHandler)
                                        .catch((error) => {
                                            setValidationMsg(error.response.data.message);
                                        });
                                        console.log(credentialResponse);
                                    });
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                        <Form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" name='email' onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <Form.Label>Password</Form.Label>
                                    <a onClick={() => { setIsForgotPasswordState(true) }} href="#"><small>forgot password?</small></a>
                                </div>
                                <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} />
                            </Form.Group>
                            <div style={{ marginTop: '30px' }}>
                                <Button variant="primary" type="submit">
                                    Log in
                                </Button>{' '}
                                <Button variant="secondary" onClick={() => {
                                    navigate('/register');
                                }}>
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Stack>
        </div>
    );
}

export default Login;
