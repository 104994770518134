import React, { ComponentType, useEffect, useState } from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
    const jwtToken = Cookies.get('jwtToken');
    const isAuthenticated = jwtToken != null;
    if (!isAuthenticated) {
        return <Navigate to={"/login"} />;
    }
    return children;
};

export default PrivateRoute;
