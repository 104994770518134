import { useContext, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, ListGroup, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import editicon from '../assets/edit_pencil.png';
import { CollectionsContext } from '../LeftRail';
import CollectionBody, { AdCardType } from './CollectionBody';
import axios from 'axios';
import React from 'react';
import CollectionsEditButtons from './CollectionEditButtons';
import DefaultCollection from './DefaultCollection';

const getArrCardsToCollections = (object) => {
  const arr: any[] = [];
  for (const [key, value] of Object.entries(object)) {
    arr.push({
      collection_id: key,
      card_ids: value as any
    });
  }
  return arr;
}

function Collections() {
  const params = useParams();
  const collectionID = params.id;
  const { collections, setCollections } = useContext(CollectionsContext);
  const collection = collections.find((collection) => collection.collection_id === collectionID);

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [validationMsg, setValidationMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // ad card states and related mutaions.
  const [initialCards,  setInitialCards] = useState<string[]>([]);
  const [cards, setCards] = useState<AdCardType[]>([]);
  const [cardsToCollections, setCardsToCollections] = useState({});

  useEffect(() => {
    setIsEditMode(false);
  }, [collectionID]);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const { name } = formData;
    const nameRegex: RegExp = /^[A-Za-z0-9\s]+$/;
    const isValidEmail = nameRegex.test(name);
    if (!isValidEmail) {
      setValidationMsg('Invalid name format.');
      setIsLoading(false);
      return;
    }
    

    axios.put(`/collection/update/${collectionID}`, 
      {
        name,
        card_ids_before: initialCards,
        card_ids_after: cards.map(card => card.id),
        cards_to_collections: getArrCardsToCollections(cardsToCollections)
      }
    )
    .then((response) => {
      const { data } = response;
      const obj = data.data;
      let arr = [ ...collections];
      const idx = arr.findIndex(col => col.collection_id === collectionID);
      arr[idx] = {
        collection_id: obj.id,
        collection_name: obj.name,
        is_default: obj.is_default,
        creation_time: obj.creation_time
      };
      setCollections(arr);
      setIsLoading(false);
      setIsEditMode(false);
    })
    .catch((error) => {
      setIsLoading(false);
      setValidationMsg(error.response.data.message);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData(() => ({
      name: collection?.collection_name
    }));
  }, [collection]);

  if (collection == null) {
    return <DefaultCollection  />;
  }

  let component = (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ marginTop: '32px', marginBottom: '32px' }}>
        <Container>
          {
            isEditMode && validationMsg != '' && 
            <Alert key={'danger'} variant={'danger'}>
              {validationMsg}
            </Alert>
          }
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {isEditMode ?
                <Form.Group controlId="formBasicName" style={{ width: '100%' }}>
                  <Form.Control 
                    type="name" 
                    placeholder="" 
                    name='name' 
                    size='lg' 
                    onChange={handleChange} 
                    disabled={isLoading}
                    value={formData.name}
                  />
                </Form.Group>
                : (<h2>{collection.collection_name}</h2>)}
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
              <div>
                {isEditMode ? (
                  <CollectionsEditButtons 
                    isLoading={isLoading} 
                    setIsEditMode={setIsEditMode} 
                    setIsLoading={setIsLoading} 
                    collectionID={collectionID}
                    collections={collections}
                    setCollections={setCollections}
                    setValidationMsg={setValidationMsg}
                    collection={collection}
                  />
                ) : (
                  <>
                    <Button variant='light' onClick={() => setIsEditMode(true)}>
                      <img
                        src={editicon}
                        width={20}
                        height={20}
                        alt="edit-icon"
                      /><span>Edit</span>
                    </Button>
                    {/*&nbsp;&nbsp;
                    <Button variant='light' onClick={() => { }}>
                      <img
                        src={shareicon}
                        width={20}
                        height={20}
                        alt="share-icon"
                      /><span>Share</span>
                    </Button>*/}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CollectionBody 
                col={3}
                isExt={false}
                isEditMode={isEditMode} 
                collectionID={collectionID} 
                cards={cards}
                setCards={setCards}
                cardsToCollections={cardsToCollections}
                setCardsToCollections={setCardsToCollections}
                onDataReceived={(cards) => {
                  setInitialCards(cards.map(card => card.id));
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

  if (isEditMode) {
    component = (
      <Form onSubmit={handleSubmit} style={{width: '100%', height: '100%'}}>
        {component}
      </Form>
    );
  }

  return component;
}

export default Collections;
