
function TermsAndConditions() {

  return (
    <div style={{ display: 'flex', marginLeft: '48px', width: '50%', marginTop: '48px' }}>
      <p>
      <h2>Terms and Conditions</h2><br/>
      
      These Terms and Conditions ("Terms") govern the use of this website and the services provided on or through this website (collectively referred to as the "Services"). By accessing or using this website and its Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use this website or its Services.<br/><br/>

      <h5>1. Website Use</h5><br/>
      1.1. Eligibility: You must be at least 13 years old or have the necessary legal capacity to enter into these Terms and use the Services.<br/><br/>

      1.2. User Account: If the Services require you to create an account, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.<br/><br/>

      1.3. Prohibited Activities: You agree not to engage in any of the following prohibited activities:<br/><br/>

      Violating any applicable laws, regulations, or these Terms;<br/>
      Transmitting any harmful, unlawful, defamatory, or misleading content;<br/>
      Attempting to gain unauthorized access to the website or its Services.<br/><br/>
      <h5>2. Intellectual Property</h5><br/>
      2.1. Ownership: All content, trademarks, logos, and other intellectual property rights on this website are owned or licensed by Roas Hunt.<br/><br/>

      2.2. Use of Content: You may use the content for personal, non-commercial purposes only. Any unauthorized use may violate copyright, trademark, and other laws.<br/><br/>

      <h5>3. Privacy</h5><br/>
      3.1. Privacy Policy: Our Privacy Policy describes how we collect, use, and disclose your personal information. Please review our Privacy Policy for more information.<br/><br/>

      <h5>4. Limitation of Liability</h5><br/>
      4.1. Disclaimer: The Services are provided "as is" and without warranties of any kind, either express or implied. Roas Hunt disclaims all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.<br/><br/>

      4.2. Limitation of Liability: Roas Hunt and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the Services or any content or materials.<br/><br/>

      <h5>5. Governing Law</h5><br/>
      5.1. Jurisdiction: These Terms shall be governed by and construed in accordance with the laws of your country/state, without regard to its conflict of law principles.<br/><br/>

      5.2. Dispute Resolution: Any disputes arising out of these Terms shall be resolved through negotiation, mediation, or arbitration as per applicable laws.<br/><br/>

      <h5>6. Changes to Terms</h5><br/>
      6.1. Modification: Roas Hunt reserves the right to modify or update these Terms at any time, without notice. Any changes will be effective immediately upon posting.<br/><br/>

      By using this website and its Services, you agree to review these Terms periodically and be aware of any modifications.<br/>

    </p>
    </div>
  );
}

export default TermsAndConditions;
