import { useContext, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, ListGroup, Modal, Row, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import saveicon from '../assets/saveicon.png';
import trashicon from '../assets/trashicon.png';
import backicon from '../assets/backicon.png';
import { CollectionsContext } from '../LeftRail';
import CollectionBody from './CollectionBody';
import axios from 'axios';
import React from 'react';

function CollectionsEditButtons({
  isLoading, 
  setIsEditMode, 
  setIsLoading, 
  collectionID, 
  collections, 
  setCollections, 
  setValidationMsg,
  collection
}) {

  function CreationModal(props) {
    let navigate = useNavigate();
    const handleDelete = () => {
      setIsLoading(true);
  
      axios.delete(`/collection/${collectionID}`)
      .then((response) => {
        const { data } = response;
        const obj = data.data;
        let arr = [ ...collections];
        const idx = arr.findIndex(col => col.collection_id === collectionID);
        if (idx !== -1) {
          arr.splice(idx, 1);
        }
  
        setCollections(arr);
        setIsLoading(false);
        setIsEditMode(false);
        props.onHide();
        navigate('/');
      })
      .catch((error) => {
        setIsLoading(false);
        setValidationMsg(error.response.data.message);
        props.onHide();
      });
    };
    
    return (
      <Modal
        {...props}
        aria-labelledby="collection-deletion-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmation Modal
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete collection <b>{collection.collection_name}</b>?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} onClick={handleDelete}>Yes</Button>
            <Button variant='secondary' onClick={props.onHide}>Cancel</Button>
          </Modal.Footer>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = useState(false);
  
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }}
          variant='primary'
          type="submit"
          disabled={isLoading}>
          <img
            src={saveicon}
            width={20}
            height={20}
            alt="save-icon"
          />&nbsp;<span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button 
          style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }}
          variant='light' 
          disabled={isLoading} 
          onClick={() => setIsEditMode(false)}>
          <img
            src={backicon}
            width={20}
            height={20}
            alt="back-icon"
          />&nbsp;<span>Cancel</span>
        </Button>&nbsp;&nbsp;
        <Button
          style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center' }} 
          variant='light'
          disabled={isLoading}
          onClick={() => setModalShow(true)}>
          <img
            src={trashicon}
            width={20}
            height={20}
            alt="trash-icon"
          />&nbsp;<span>Delete</span>
        </Button>
      </div>
      <CreationModal 
        show={modalShow}
        onHide={() => setModalShow(false)} 
      />
    </>
  );
}

export default CollectionsEditButtons;
