import { ReactNode, useContext } from "react";
import '../AdCard.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import { AdCardType } from "../CollectionBody";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import postimageplaceholder from '../../assets/postimageplaceholder.jpg';
import { AdDownloadContext } from "../AdDownloadContext";

const getImage = (getAssetUrl, image_url, secondary_image_url) => {
  getAssetUrl(image_url);
  return (
    <div style={{
    overflow: 'hidden',
    borderStyle: 'solid',
    borderColor: '#f7f7f7',
    borderRadius: '8px'
    }}>
      <img src={image_url}
        onError={({currentTarget}) => {
            if (currentTarget.src === secondary_image_url) {
                //both bucket and source media returned error
                currentTarget.onerror = null;
                currentTarget.src = postimageplaceholder;
                getAssetUrl(null);
                return;
            }
            currentTarget.onerror = null; // prevents looping
            currentTarget.src=secondary_image_url;
            getAssetUrl(secondary_image_url);
        }}
        alt="attachment-image"
        style={{
          maxHeight: '600px', width: '100%', objectFit: 'contain',
          background: 'gainsboro'
        }}
      />
    </div>
  );
}

function AdMultiImagesAttachment(card: AdCardType) {

  const { data } = useContext(AdDownloadContext);

  const cardOverall = card.asset;
  if (cardOverall == null) {
    return null;
  }

  data?.adDownloadClosure.setAdDownloadData({
    format: 'multi-images',
    media_urls: []
  });

  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {cardOverall.images.map((image, idx) => {
          let component: ReactNode | null = null;
          if (image.image_url != null) {
            component = getImage(
              (url) => {
                if (url != null) {
                  data?.adDownloadClosure.pushMedia(url, idx);
                }
              },
              image.image_url,
              image.image_url_secondary,
            );
          } 

          return (
            <SwiperSlide>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {component}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div style={{ background: 'rgba(0,0,0,0.05)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
          <Container>
            <Row>
              <Col xs={7}>
                <Stack direction='vertical'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}>
                  <small style={{ fontSize: '8px' }}>
                    {cardOverall.caption}
                  </small>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <small style={{ fontSize: '8px', fontWeight: '600' }}>
                      {cardOverall.caption != null ? cardOverall.title : card.pageName}
                    </small>
                    <small style={{ fontSize: '8px' }} className="description-clamp">
                      {cardOverall.link_description}
                    </small>
                  </div>
                </Stack>
              </Col>
              <Col xs={5}>
                {cardOverall.cta_text != null ?
                  (<div style={{ 
                      display: 'flex', 
                      flexDirection: 'row', 
                      alignItems: 'center', 
                      height: '100%', 
                      justifyContent: 'flex-end', 
                      fontSize: '16px', 
                      padding: '8px' 
                    }}>
                    <Button variant="light" size='sm'>
                      <small className="description-clamp">{cardOverall.cta_text}</small>
                    </Button>
                  </div>) : null
                }
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
}

export default AdMultiImagesAttachment;
