import { Stack, Badge } from 'react-bootstrap';
import './HomeRail.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import metadataJson from '../metadata.json';
import { Badge as CBadge } from 'customizable-react-badges';
import axios from 'axios';
import { ShimmerButton } from "react-shimmer-effects";

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);


function HomeRail() {

  const location = useLocation();
  const navigate = useNavigate();

  // Access the current path
  const currentPath = location.pathname;

  const [selectedValue, setSelectedValue] = useState(0);
  const [hasExtension, setHasExtension] = useState<boolean | null>(false);
  const [isAgencyGuideLoading, setIsAgencyGuideLoading] = useState(false);

  useEffect(() => {
    if (currentPath === '/' || currentPath.match(/collections/) != null) {
      setSelectedValue(1);
    } else if (currentPath.match(/discover/) != null) {
      setSelectedValue(2);
    }
  }, [currentPath])


  useEffect(() => {
    if (isChrome) {
      chrome?.runtime?.sendMessage(metadataJson.extension_id, { message: "version" },
        function (reply) {
          if (reply) {
            if (reply.version) {
              if (reply.version >= 1.0) {
                setHasExtension(true);
              }
            }
          }
          else {
            setHasExtension(false);
          }
        });
    }
  })

  const commit = () => {
    setIsAgencyGuideLoading(true);
    axios.post('/profile/agency_guide_request', {})
      .then((response) => {
        alert("Success! We will reachout with next steps!");
        setIsAgencyGuideLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsAgencyGuideLoading(false);
      });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className='pill_container'>
        <a href="#" onClick={() => { setSelectedValue(1); navigate('/collections') }}>
          <Badge pill bg="secondary" className={selectedValue === 1 ? 'pill_selected' : 'pill'}>
            Collections
          </Badge>
        </a>
      </div>
      <div className='pill_container'>
        <a href="#" onClick={() => { setSelectedValue(2); navigate('/discover'); }}>
          <Badge pill bg="secondary" className={selectedValue === 2 ? 'pill_selected' : 'pill'}>
            Discover
          </Badge>
        </a>
      </div>
      <div className='pill_container'>
        <a href="https://chrome.google.com/webstore/detail/roas-hunt-save-fb-ads-for/olmkgiikkajilgjbeghkldeamaeekdbk" target="_blank">
          <Badge pill bg="secondary" className={'pill'}>
            <CBadge
              bgColor={hasExtension ? "green" : "orange"}
              content=""
              verticalAlignment="top"
              horizontalAlignment="right"
              style={{
                height: '5px',
                padding: '0px',
                minWidth: '5px',
              }}
            >
              <div style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                {hasExtension === true ? 'Extension' : 'Install Extension'}
              </div>
            </CBadge>
          </Badge>
        </a>
      </div>
      <div className='pill_container'>
        <a href="https://www.facebook.com/ads/library/" target="_blank">
          <Badge pill bg="secondary" className={'pill'}>
            FB Ad Library
          </Badge>
        </a>
      </div>
      <div className='pill_container'>
        {isAgencyGuideLoading ? <div style={{marginBottom: '-20px'}}><ShimmerButton size="sm" /></div>:
        <a href="#" onClick={() => { 
          commit();
         }}>
          <Badge pill bg="secondary" className={'pill'}>
            Get Custom Ads
          </Badge>
        </a>}
      </div>
    </div>
  );
}

export default HomeRail;
