import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Card, Container, Form, Stack, Row, Col, ButtonGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { ShimmerCategoryItem } from "react-shimmer-effects";
import CardRadio from './components/CardRadio';

function Onboarding() {

  const [formData, setFormData] = useState({
    answer: 2
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    console.log(formData);
    const { answer } = formData;
    axios.post('/onboarding', { answer })
      .then((response) => {
        setIsLoading(false);
        window.location.href = `/`;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  if (isLoading) {
    return (
      <div style={{ width: '100%' }}>
        <Stack style={{ width: '100%', marginTop: '100px' }}>
          <Container>
            <Row>
              <Col>
                <ShimmerCategoryItem />
                <ShimmerCategoryItem />
                <ShimmerCategoryItem />
                <ShimmerCategoryItem />
              </Col>
            </Row>
          </Container>
        </Stack>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <Stack style={{ width: '100%', marginTop: '100px' }}>
        <Container>
          <Row>
            <Col sm={7}>
              <div>
                <h2>
                  Are you a <b>Marketer, Media Buyer, Creative Director?</b>
                </h2>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col sm={7}>
              <Form onSubmit={handleSubmit}>
                <Form.Group style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <CardRadio onClick={() => { setFormData({ answer: 1 }) }} isEqual={() => formData.answer == 1}>
                    <Form.Check // prettier-ignore
                      type={'radio'}
                      name="answer"
                      label={`Yes`}
                      checked={formData.answer === 1}
                      value={1}
                      onChange={handleChange}
                    />
                  </CardRadio>
                  <div style={{ marginLeft: '24px' }}>
                    <CardRadio onClick={() => { setFormData({ answer: 0 }) }} isEqual={() => formData.answer == 0}>
                      <Form.Check // prettier-ignore
                        type={'radio'}
                        name="answer"
                        label={`No`}
                        checked={formData.answer === 0}
                        value={0}
                        onChange={handleChange}
                      />
                    </CardRadio>
                  </div>
                </Form.Group>
                <div>
                  <Button type="submit" size="lg" variant="primary" style={{ marginTop: '32px', height: '48px' }}>
                    Done
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Stack>
    </div>
  );
}

export default Onboarding;
