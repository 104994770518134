import { useContext, useEffect, useRef, useState } from "react";
import { ShimmerPostList } from "react-shimmer-effects";
import StackGrid, { transitions } from "react-stack-grid";
import AdCard from "../collections/AdCard";
import { DiscoverContext } from "./Discover";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Collapse, Container, Row, Stack } from "react-bootstrap";

import applewatch_collection from '../collection_assets/applewatch_collection.png';
import homedecor_collection from '../collection_assets/homedecor_collection.png';
import food_collection from '../collection_assets/food_collection.png';
import ai_collection from '../collection_assets/ai_collection.png';
import cv_collection from '../collection_assets/cv_collection.png';
import webdev_collection from '../collection_assets/webdev_collection.png';
import health_collection from '../collection_assets/health_collection.png';
import finance_collection from '../collection_assets/finance_collection.png';

function DefaultGrid() {
  let navigate = useNavigate();
  const defaultContent = (
    <Container style={{ maxWidth: '1000px', marginTop: '80px' }}>
      <Row style={{ marginBottom: '20px' }}>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/WSgtDe8hchGKCKaK47MP'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={applewatch_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Apple Watch and Magsafe Charging Docks</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/GbvKh9DcORG4zlOxjob2'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={homedecor_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Home Decor</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/v69PnHBV5uYFAia87KDe'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={food_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Food and Beverage</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/RVRkZUbwvC0dvxs8UibD'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={ai_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>AI Automation and Chatbots</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/amu3sXMTg5rmmvFWBwQE'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={cv_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>CV Writing and Career Counseling</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/HjYpCTmUSmReOYa6J6hc'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={webdev_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Web Dev</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/6ofaBwBOMu6Qa9DrEC6r'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={health_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Health & Wellness</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={() => { navigate('/ext/collections/y0MBlH7Y9k50NZLsoKDQ'); }} style={{ cursor: "pointer" }} className="button_card">
            <Card.Img variant="top" src={finance_collection} height={150} style={{ objectFit: 'cover' }} />
            <Card.Body>
              <Card.Text>Finance SaaS</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return defaultContent;
}
export default DefaultGrid;
