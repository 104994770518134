
function PrivacyPolicy() {

  return (
    <div style={{ display: 'flex', marginLeft: '48px', width: '50%', marginTop: '48px' }}>
      <p>
      <h2>Privacy Policy</h2><br/>
      Effective Date: 10/01/2023<br/><br/>

      <h5>Introduction</h5><br/>
      Welcome to Roas Hunt. This Privacy Policy describes how we collect, use, disclose, and safeguard your personal information when you visit our website or use our services. By accessing or using our website, you consent to the practices described in this Privacy Policy.<br/><br/>

      <h5>Information We Collect</h5><br/>
      We may collect the following types of information:<br/><br/>

      Personal Information: We may collect personal information such as your name, email address, phone number, or other identifiers when you voluntarily provide this information to us.<br/><br/>

      Non-Personal Information: We may collect non-personal information such as your IP address, browser type, operating system, referring website, and usage patterns.<br/><br/>

      <h5>How We Use Your Information</h5><br/>
      We may use the collected information for various purposes, including but not limited to:<br/><br/>

      Providing Services: To provide and maintain our services and respond to your inquiries.<br/><br/>

      Improving User Experience: To enhance our website, products, and services based on your feedback and usage patterns.<br/><br/>

      Marketing and Communications: To send you promotional materials, newsletters, and updates about our products and services.<br/><br/>

      Legal Compliance: To comply with legal obligations and resolve any disputes.<br/><br/>

      <h5>Sharing of Information</h5><br/>
      We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this Privacy Policy.<br/><br/>

      Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.<br/><br/>

      Legal Compliance: We may disclose your information to comply with legal obligations or protect our rights, privacy, safety, or property.<br/><br/>

      <h5>Security Measures</h5><br/>
      We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.<br/><br/>

      <h5>Your Rights</h5><br/>
      You have the right to access, correct, and delete your personal information. If you wish to exercise these rights or have any inquiries regarding your personal data, please contact us at arlan@roashunt.com.<br/><br/>

      <h5>Changes to this Privacy Policy</h5><br/>
      We reserve the right to update this Privacy Policy at any time. The updated policy will be effective immediately upon posting on this page.<br/><br/>

      <h5>Contact Us</h5><br/>
      If you have any questions or concerns about this Privacy Policy, please contact us at arlan@roashunt.com.<br/>

    </p>
    </div>
  );
}

export default PrivacyPolicy;
