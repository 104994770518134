import { Button, ButtonGroup, Card, Col, Container, Form, ListGroup, Row, Stack, Modal, DropdownButton, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import editicon from '../assets/edit_pencil.png';
import addicon from '../assets/addiconsquare.png';
import trashicon from '../assets/trashicon.png';
import moveicon from '../assets/moveicon.png';
import graycheckicon from '../assets/graycheckicon.png';
import infoicon from '../assets/infoicon.png';

import fbicon from '../assets/fbicon.png';
import anicon from '../assets/anicon.png';
import igicon from '../assets/igicon.jpg';
import msgricon from '../assets/msgricon.jpg';
import profileplaceholder from '../assets/profileplaceholder.jpg';
import downloadicon from '../assets/downloadicon.png';

import StackGrid from "react-stack-grid";
import ClampLines from 'react-clamp-lines';
import { AdCardType } from './CollectionBody';
import { Parser } from 'html-to-react';
import './AdCard.css';
import AdAttachment from './AdAttachment';
import { useContext, useMemo, useState } from 'react';
import React from 'react';
import { CollectionsContext } from '../LeftRail';
import { AdDownloadContext, adDownloadInit } from './AdDownloadContext';

function convertSecondsString(seconds: number): string {
  const secondsInDay = 86400;
  const days = Math.floor(seconds / secondsInDay);

  if (days >= 7) {
    const secondsInWeek = 604800;
    const weeks = Math.round(seconds / secondsInWeek * 10) / 10;

    return `${weeks} weeks`;
  }

  return `${days} days`;
}

function convertSecondsToDateStr(seconds: number): string {
  const millisecondsPerSecond = 1000;
  const milliseconds = seconds * millisecondsPerSecond;
  const date = new Date(milliseconds);

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}


function CreationModal(props) {


  return (
    <Modal
      size="lg"
      {...props}
      aria-labelledby="collection-creation-modal"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Card style={{ width: '760px', height: 'fit-content' }}>
          <Card.Body>
            {props.metadata}
            {props.profile}
            <Card.Text>
              {props.reactElement}
            </Card.Text>
            <small>
              <Button
                variant='secondary'
                style={{ marginTop: '8px', marginBottom: '8px' }}
                onClick={async () => { await props.adDownloadClosure.downloadAdMedia(); }}>
                <img
                  src={downloadicon}
                  width={16}
                  height={16}
                  alt="download-icon"
                />&nbsp;Download
              </Button>
            </small>
            <AdAttachment {...props.card} />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function AdCard({ onRemove, onMove, isEditMode, isDiscoverMode = false, saved = false, ...card }: AdCardType & { isEditMode: boolean, onRemove: () => void, onMove: (collectionID) => void, isDiscoverMode: boolean, saved: boolean }) {

  const adDownloadClosure = adDownloadInit();

  const [modalShow, setModalShow] = useState(false);
  const { collections, setCollections } = useContext(CollectionsContext);
  const [isSaved, setIsSaved] = useState(saved);

  const parser = Parser();
  const reactElement = parser.parse(card.body);


  const platforms = card.platforms.map((platform, idx) => {
    let icon: any = null;
    if (platform === "facebook") {
      icon = <img
        style={{ borderRadius: '50%', marginRight: '4px' }}
        src={fbicon}
        width={18}
        height={18}
        alt="fb-icon"
      />;
    } else if (platform === "instagram") {
      icon = <img
        style={{ borderRadius: '50%', marginRight: '4px' }}
        src={igicon}
        width={18}
        height={18}
        alt="ig-icon"
      />;
    } else if (platform === "audience_network") {
      icon = <img
        style={{ borderRadius: '50%', marginRight: '4px' }}
        src={anicon}
        width={18}
        height={18}
        alt="an-icon"
      />;
    } else if (platform === "messenger") {
      icon = <img
        style={{ borderRadius: '50%', marginRight: '4px' }}
        src={msgricon}
        width={18}
        height={18}
        alt="msgr-icon"
      />;
    }
    return (<small key={idx} style={{ marginTop: '8px' }}>{icon}{platform}</small>);
  })

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      Run time is how long the ad was active since last check.
    </Tooltip>
  );

  const metadata = (
    <Stack style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginBottom: '12px' }}>
      {!isDiscoverMode && <><div>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <small><img
            src={infoicon}
            width={18}
            height={18}
            alt="info-icon"
          />Run time: {convertSecondsString(card.timeRunning)}</small>
        </OverlayTrigger>
      </div>
        <div>
          <small>{card.isActive ? 'Active' : 'Inactive'}</small>
        </div></>}
      <div>
        <small>Started running on {convertSecondsToDateStr(card.startDate)}</small>
      </div>
      <div>
        <small>Platforms:</small>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
        {platforms}
      </div>
    </Stack>
  );

  const profile = (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '16px'
    }}>
      <img height={30} width={30} src={`https://storage.googleapis.com/roas_hunt_media/media/${card.id}_profile.jpg`}
        onError={({ currentTarget }) => {
          if (currentTarget.src === card.pageProfileUri) {
            //both bucket and source media returned error
            currentTarget.onerror = null;
            currentTarget.src = profileplaceholder;
            return;
          }
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = card.pageProfileUri;
        }}
        alt="profile-icon"
        style={{
          borderRadius: '50%', marginRight: '8px'
        }}
      />
      <Stack style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{card.pageName}</b>
        <small>Sponsored</small>
      </Stack>
    </div>
  );

  const discoverDropdown = (
    <Dropdown drop="start">
      <Dropdown.Toggle as={React.forwardRef<HTMLAnchorElement>((props, ref) => {
        const onClick = props['onClick'];
        return (
          <a href={isSaved ? undefined : ""}
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              background: isSaved ? '#a9ffa9' : undefined,
              borderRadius: isSaved ? '100%' : undefined
            }}
            ref={ref}
            onClick={(e) => {
              if (isSaved) {
                return;
              }
              e.preventDefault();
              return onClick(e);
            }}>
            <img
              src={isSaved ? graycheckicon : addicon}
              width={28}
              height={28}
              alt="add-icon"
            />
          </a>
        );
      })} id="dropdown-discover-components">
        Icon Toggle
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {collections.map((collection) => (
          <Dropdown.Item eventKey="1" style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '500px'
          }}
            onClick={() => {
              onMove(collection.collection_id)
              setIsSaved(true);
            }}>
            <img width={20} height={20} src={moveicon} />&nbsp;Save to {collection.collection_name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const editDropdown = (
    <Dropdown drop="start">
      <Dropdown.Toggle as={React.forwardRef<HTMLAnchorElement>((props, ref) => {
        const onClick = props['onClick'];
        return (
          <a href=""
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              return onClick(e);
            }}>
            <img
              src={editicon}
              width={20}
              height={20}
              alt="pencil-icon"
            />
          </a>
        );
      })} id="dropdown-custom-components">
        Icon Toggle
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" onClick={onRemove}>
          <img width={20} height={20} src={trashicon} />&nbsp;Remove
        </Dropdown.Item>
        <Dropdown.Divider />
        {collections.map((collection) => (
          <Dropdown.Item eventKey="2" style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '500px'
          }}
            onClick={() => {
              onMove(collection.collection_id)
            }}>
            <img width={20} height={20} src={moveicon} />&nbsp;Move to {collection.collection_name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      <AdDownloadContext.Provider value={{ data: { adDownloadClosure } }}>
        <Card style={{ width: '350px' }}>
          {isEditMode &&
            editDropdown
          }
          {
            isDiscoverMode &&
            discoverDropdown
          }
          <Card.Body>
            {metadata}
            {profile}
            <Card.Text>
              <div className="body-clamp">
                {reactElement}
              </div>
              {!isEditMode && <small>
                <Button
                  variant='secondary'
                  style={{ marginTop: '8px' }}
                  onClick={() => setModalShow(true)}>
                  See More
                </Button>
              </small>}
              {!isEditMode && <small>
                <Button
                  variant='secondary'
                  style={{ marginTop: '8px', marginLeft: '8px' }}
                  onClick={async () => { await adDownloadClosure.downloadAdMedia(); }}>
                  <img
                    src={downloadicon}
                    width={16}
                    height={16}
                    alt="download-icon"
                  />
                </Button>
              </small>}
            </Card.Text>
            <AdAttachment {...card} />
          </Card.Body>
        </Card>
        <CreationModal
          adDownloadClosure={adDownloadClosure}
          show={modalShow}
          onHide={() => setModalShow(false)}
          metadata={metadata}
          profile={profile}
          reactElement={reactElement}
          card={card}
        />
      </AdDownloadContext.Provider>
    </>
  );
}

export default AdCard;
