import { useContext, useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import icon from './assets/bookmarkicon.png';
import vectordown from './assets/Expand_down.png';
import vectorup from './assets/Expand_up.png';
import { createClient } from 'contentful';
import metadataJson from './metadata.json';
import { useNavigate, useParams } from 'react-router-dom';
import { ShimmerCategoryItem } from "react-shimmer-effects";
import './css_additions/card.css';
import { Timestamp } from '@google-cloud/firestore';
import axios from 'axios';
import { CollectionsContext } from './LeftRail';


type CollectionData = {
    collection_id: string,
    is_default: boolean,
    collection_name: string,
    creation_time: Timestamp
};

function CollectionTab({ fromIndex }) {
    const {collections, setCollections} = useContext(CollectionsContext);
    const params = useParams();
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        axios.get('/collection', {})
            .then((response) => {
                setCollections(response.data.collections);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    }, []);

    let items = (<>
        {collections.map((item, idx) => {
            return (
                <ListGroup.Item key={item.collection_id} style={{
                    background: params.id === item.collection_id ? 'rgb(236, 236, 236)' : undefined
                }} action onClick={() => {
                    navigate(`${fromIndex ? 'collections/' : ''}${item.collection_id}`);
                }}>
                    <span>{item.collection_name}</span>
                </ListGroup.Item>
            );

        })}
    </>);
    if (isLoading) {
        items = (
            <>
                <ListGroup.Item key={1}>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item key={2}>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
                <ListGroup.Item key={3}>
                    <ShimmerCategoryItem />
                </ListGroup.Item>
            </>
        );
    }
    return (
        <>
            <ListGroup.Item style={{ border: 'none' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '-.5rem' }}>
                        <img
                            style={{ marginRight: '8px', marginBottom: '.5rem' }}
                            src={icon}
                            width={20}
                            height={20}
                            alt="order-icon"
                        /><h6>Your Collections</h6>
                    </div>
                </div>
            </ListGroup.Item>
            <ListGroup style={{ margin: '16px', marginTop: '0px' }}>
                {items}
            </ListGroup>
        </>
    );
}

export default CollectionTab;
