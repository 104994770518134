import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Form, Stack } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ShimmerThumbnail } from "react-shimmer-effects";


function ForgotPassword() {
  const params = useParams();
  const email = params.email;
  const token = params.token;

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  });

  const [validationMsg, setValidationMsg] = useState('');
  const [msg, setMsg] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const { password, passwordConfirm } = formData;

    if (password == '' || passwordConfirm == '') {
      setValidationMsg('Some fields are empty.');
      return;
    }

    if (password !== passwordConfirm) {
      setValidationMsg('Passwords do not match.');
      return;
    }

    const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    const isValidPassword = passwordRegex.test(password);
    if (!isValidPassword) {
      setValidationMsg('Invalid password format.');
      return;
    }

    axios.post('/login/password_reset', {email, forgot_token: token, new_password: password})
      .then((response) => {
        setMsg(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        setValidationMsg(error.response.data.message);
     });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack style={{ width: '18rem', marginTop: '100px' }}>
        {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
          {validationMsg}
        </Alert>}
        {msg != null && <Alert key={'success'} variant={'success'}>
          {msg}
        </Alert>}
        <Card className='card'>
          <Card.Body>
            <Card.Title>Reset Password</Card.Title>
            <Form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name='password' onChange={handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control type="password" placeholder="Password" name='passwordConfirm' onChange={handleChange} />
              </Form.Group>
              <div style={{ marginTop: '30px' }}>
                <Button variant="primary" type="submit">
                  Change
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Stack>
    </div>
  );
}

export default ForgotPassword;
