import { ReactNode, useContext } from "react";
import '../AdCard.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import { AdCardType } from "../CollectionBody";
import { getImage } from "./AdImageAttachment";
import { AdVideoImpl } from "./AdVideoAttachment";
import { AdDownloadContext } from "../AdDownloadContext";


function AdCarouselAttachment(card: AdCardType) {
  const { data } = useContext(AdDownloadContext);
  if (card.asset == null) {
    return null;
  }
  const cardOverall = card;
  data?.adDownloadClosure.setAdDownloadData({
    format: 'carousel',
    media_urls: []
  });
  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {card.asset.cards.map((card, idx) => {
          let component: ReactNode | null = null;
          if (card.image_url != null) {
            component = getImage(
              (url) => {
                if (url != null) {
                  data?.adDownloadClosure.pushMedia(url, idx);
                }
              },
              card.image_url,
              card.image_url_secondary,
              card.caption,
              card.title,
              card.link_description,
              card.cta_text,
              cardOverall.pageName,
              true
            );
          } else if (card.video_url != null) {
            const props = {
              getAssetUrl: (url) => {
                if (url != null) {
                  data?.adDownloadClosure.pushMedia(url, idx);
                }
              },
              light: card.video_preview_image_url,
              url: card.video_url,
              secondary_light: card.video_preview_image_url_secondary,
              secondary_url: card.video_url_secondary,
              width: 240,
              caption: card.caption,
              title: card.title,
              link_description: card.link_description,
              cta_text: card.cta_text,
              page_name: cardOverall.pageName,
              is_small: true
            };
            component = <AdVideoImpl
              {...props}
            />;
          }

          return (
            <SwiperSlide>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {component}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default AdCarouselAttachment;
