import { ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { Button, Carousel, Col, Container, Row, Stack } from "react-bootstrap";
import '../AdCard.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { AdCardType } from "../CollectionBody";
import postimageplaceholder from '../../assets/postimageplaceholder.jpg';
import { AdDownloadContext } from "../AdDownloadContext";


export const getImage = (getAssetUrl, image_url, secondary_image_url, caption, title, link_description, cta_text, page_name, is_small = false) => {

  getAssetUrl(image_url);
  return (
    <>
      <img src={image_url}
        onError={({currentTarget}) => {
            if (currentTarget.src === secondary_image_url) {
                //both bucket and source media returned error
                currentTarget.onerror = null;
                currentTarget.src = postimageplaceholder;
                getAssetUrl(null);
                return;
            }
            currentTarget.onerror = null; // prevents looping
            currentTarget.src=secondary_image_url;
            getAssetUrl(secondary_image_url);
        }}
        alt="attachment-image"
        style={{
          maxHeight: '600px', width: '100%', objectFit: 'contain',
          background: 'gainsboro'
        }}
      />
      <div style={{ background: 'rgba(0,0,0,0.05)' }}>
        <Container>
          <Row>
            <Col xs={7}>
              <Stack direction='vertical'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}>
                <small style={{ fontSize: '8px' }}>
                  {caption}
                </small>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <small style={{ fontSize: '8px', fontWeight: '600' }}>
                    {caption != null ? title : page_name}
                  </small>
                  <small style={{ fontSize: '8px' }} className="description-clamp">
                    {link_description}
                  </small>
                </div>
              </Stack>
            </Col>
            <Col xs={5}>
              {cta_text != null ?
                (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', justifyContent: 'flex-end', fontSize: is_small ? '16px' : undefined, padding: '8px' }}>
                  <Button variant="light" size={is_small ? 'sm' : undefined}>
                    <small className="description-clamp">{cta_text}</small>
                  </Button>
                </div>) : null
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

function AdImageAttachment(card: AdCardType) {
  const { data } = useContext(AdDownloadContext);

  if (card.asset == null) {
    return null;
  }

  return (
    <div style={{
    overflow: 'hidden',
    borderStyle: 'solid',
    borderColor: '#f7f7f7',
    borderRadius: '8px'
    }}>{
      getImage(
        (url) => {
          if (url == null) {
            data?.adDownloadClosure.setAdDownloadData(null);
          } else {
            data?.adDownloadClosure.setAdDownloadData({
              format: 'image',
              media_urls: [url]
            });
          }
        },
        card.asset.images[0].image_url,
        card.asset.images[0].image_url_secondary,
        card.asset.caption,
        card.asset.title,
        card.asset.link_description,
        card.asset.cta_text,
        card.pageName,
      )}
    </div>
  );
}

export default AdImageAttachment;
