import { useContext, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, ListGroup, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import editicon from '../assets/edit_pencil.png';
import { CollectionsContext } from '../LeftRail';
import CollectionBody, { AdCardType } from './CollectionBody';
import axios from 'axios';
import React from 'react';
import CollectionsEditButtons from './CollectionEditButtons';

function DefaultCollection() {
  
  // ad card states and related mutaions.
  const [cards, setCards] = useState<AdCardType[]>([]);
  const [cardsToCollections, setCardsToCollections] = useState({});


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ marginTop: '32px', marginBottom: '32px' }}>
        <Container>
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h2>Your Saved Ads</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <CollectionBody 
                col={3}
                isExt={false}
                collectionID={null}
                isEditMode={false}  
                cards={cards}
                setCards={setCards}
                cardsToCollections={cardsToCollections}
                setCardsToCollections={setCardsToCollections}
                onDataReceived={(cards) => {}}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default DefaultCollection;
