import { useContext, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, ListGroup, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import editicon from '../assets/edit_pencil.png';
import { CollectionData, CollectionsContext } from '../LeftRail';
import CollectionBody, { AdCardType } from './CollectionBody';
import axios from 'axios';
import React from 'react';
import CollectionsEditButtons from './CollectionEditButtons';
import DefaultCollection from './DefaultCollection';

const getArrCardsToCollections = (object) => {
  const arr: any[] = [];
  for (const [key, value] of Object.entries(object)) {
    arr.push({
      collection_id: key,
      card_ids: value as any
    });
  }
  return arr;
}

function ExtCollections({isSmallScreen}) {
  const params = useParams();
  const collectionID = params.id;
  const { collections, setCollections } = useContext(CollectionsContext);
  const [ collection, setCollection ] = useState<CollectionData | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  
  // ad card states and related mutaions.
  const [initialCards,  setInitialCards] = useState<string[]>([]);
  const [cards, setCards] = useState<AdCardType[]>([]);
  const [cardsToCollections, setCardsToCollections] = useState({});

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/collection/ext/${collectionID}`, {})
        .then((response) => {
            setCollections(response.data.collections);
            setCollection(response.data.collections[0]);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
  }, []);

  if (collection == null) {
    return null;
  }

  let header = (
    <Row>
      <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h2>{collection.collection_name}</h2>
      </Col>
      <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div>
            <>
              <Button variant='primary' href="https://roashunt.com" target="_blank">
                <span>Go to Home Page</span>
              </Button>
              
            </>
        </div>
      </Col>
    </Row>
  );

  if (isSmallScreen) {
    header = (
      <>
      <Row>
        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h2>{collection.collection_name}</h2>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <div>
              <>
                <Button variant='primary' href="https://roashunt.com" target="_blank">
                  <span>Go to Home Page</span>
                </Button>
                
              </>
          </div>
        </Col>
      </Row>
      </>
    )
  }

  let component = (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ marginTop: '32px' }}>
        <Container>
          {header}
          <Row>
            <Col>
              <CollectionBody 
                col={isSmallScreen ? 1 : 3}
                isExt={true}
                isEditMode={false} 
                collectionID={collectionID} 
                cards={cards}
                setCards={setCards}
                cardsToCollections={cardsToCollections}
                setCardsToCollections={setCardsToCollections}
                onDataReceived={(cards) => {
                  setInitialCards(cards.map(card => card.id));
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

  return component;
}

export default ExtCollections;
