import { Button, ButtonGroup, Card, Col, Container, Form, ListGroup, Row, Stack } from 'react-bootstrap';
import { useHref, useParams } from 'react-router-dom';
import editicon from '../assets/edit_pencil.png';
import shareicon from '../assets/shareicon.png';
import StackGrid, { transitions } from "react-stack-grid";
import AdCard from './AdCard';
import { useEffect, useReducer, useRef, useState } from 'react';
import axios from 'axios';
import { ShimmerPostList } from "react-shimmer-effects";

const { fade } = transitions;

export type AdCardType = Readonly<{
  id: string,
  adArchiveID: string,
  //seconds
  timeRunning: number,
  isActive: boolean,
  startDate: number,
  platforms: string[],
  pageName: string,
  pageProfileUri: string,
  body: string,
  asset: {
    format: string,
    images: {
      image_url: string,
      image_url_secondary: string
    }[],
    videos: {
      video_url: string,
      video_preview_image_url: string,
      video_url_secondary: string,
      video_preview_image_url_secondary: string
    }[],
    caption: string | null,
    title: string | null,
    link_description: string | null,
    cta_text: string | null,
    cta_type: string | null,
    cards: {
      image_url: string | null,
      image_url_secondary: string | null,
      caption: string | null,
      cta_type: string,
      cta_text: string,
      link_url: string | null,
      title: string | null,
      link_description: string | null,
      body: string | null,
      video_url: string | null,
      video_preview_image_url: string | null,
      video_url_secondary: string | null,
      video_preview_image_url_secondary: string | null
    }[],

  } | undefined
}>;

function CollectionBody({
  col,
  isExt,
  collectionID, 
  isEditMode,  
  cards,
  setCards, 
  cardsToCollections, 
  setCardsToCollections,
  onDataReceived,
}) {
  const grid = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    let uri = collectionID == null ? '/collection/ads/' : `/collection/ads/${collectionID}`;
    if (isExt) {
      uri = `/collection/ads/ext/${collectionID}`;
    }
    axios.get(uri, {})
      .then((response) => {
        setCards(response.data.cards);
        onDataReceived(response.data.cards);
        setTimeout(() => setIsLoading(false), 100);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [collectionID]);

  useEffect(() => {
    setTimeout(() => {
      if (grid.current) {
        grid.current.updateLayout();
      }
    }, 1000);
  }, [isLoading]);

  if (isLoading) {
    return (
      <div style={{ marginTop: '32px' }}>
        <ShimmerPostList postStyle="STYLE_FOUR" col={col} row={2} gap={30} />
      </div>
    );
  }

  return (
    <div style={{ marginTop: '32px' }} key={`grid_${collectionID}`}>
      <StackGrid
        gridRef={r => (grid.current = r)}
        columnWidth={350}
        gutterWidth={24}
        gutterHeight={32}
        monitorImagesLoaded={true}
        appearDelay={30}
        appear={fade.appeared}
        appeared={fade.appeared}
        enter={fade.enter}
        entered={fade.entered}
        leaved={fade.leaved}
      >
        {cards.map((card, idx) => (
          <div key={`grid_div_${card.id}`} className="item" style={{width: '100%'}}>
            <AdCard 
              onMove={(collectionID) => {
                if (!(collectionID in cardsToCollections)) {
                  cardsToCollections[collectionID] = [];
                }
                cardsToCollections[collectionID].push(card.id);
                setCardsToCollections(cardsToCollections);
                setCards(cards.filter(x => x.id !== card.id))
              }}
              onRemove={() => {
                setCards(cards.filter(x => x.id !== card.id))
              }} 
              isEditMode={isEditMode} 
              {...card} 
            />
          </div>
        ))}
      </StackGrid>
    </div>
  );
}

export default CollectionBody;
