import { Card, Col, Collapse, Container, Row, Stack } from "react-bootstrap";
import bgimage from '../assets/announcementbg.png';
import SearchBar from "material-ui-search-bar";
import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import { AdCardType } from "../collections/CollectionBody";
import DiscoverGrid from "./DiscoverGrid";
import { CollectionData, CollectionsContext } from "../LeftRail";

export const DiscoverContext = React.createContext<{
  cards: {
    is_saved: boolean,
    inventory_col_name: string,
    card: AdCardType
  }[],
  setCards: React.Dispatch<React.SetStateAction<{
    is_saved: boolean,
    inventory_col_name: string,
    card: AdCardType
  }[]>
  >
}>({ cards: [], setCards: () => { } });

function Discover() {
  const [searchState, setSearchState] = useState('');
  const [alreadyLoadedQuery, setAlreadyLoadedQuery] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cards, setCards] = useState<{
    is_saved: boolean,
    inventory_col_name: string,
    card: AdCardType
  }[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [isMoreAdsActive, setIsMoreAdsActive] = useState(true);

  const [collections, setCollections] = useState<CollectionData[]>([]);

  useEffect(() => {
    axios.get('/collection', {})
      .then((response) => {
        setCollections(response.data.collections);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = () => {
    if (searchState === alreadyLoadedQuery) {
      return;
    }
    const search = searchState;
    console.log(search);
    if (search === '') {
      return;
    }
    setHasNextPage(true);
    setIsLoading(true);
    setPageNumber(1);
    // override base url
    axios.post('/search', { query: search }, { baseURL: 'https://roas-hunt-search-43n265a2nq-uc.a.run.app' })
      .then((response) => {
        const data = response.data;
        setIsLoading(false);
        setAlreadyLoadedQuery(search);
        setCards(data.cards);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    // should show upsell
    axios.get('/profile/agency_guide_upsell_dismissed', {})
      .then((response) => {
        const data = response.data;
        const is_dismissed = data?.is_dismissed ?? false;
        setIsMoreAdsActive(!is_dismissed);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <CollectionsContext.Provider value={{ collections, setCollections }}>
      <DiscoverContext.Provider value={{ cards, setCards }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Stack style={{ width: '100%', marginTop: '32px' }}>
            <Container>
              <Row style={{ justifyContent: 'center' }}>
                <Col sm={7}>
                  <SearchBar
                    value={''}
                    placeholder={'Discover winning creatives'}
                    onChange={(newValue) => { setSearchState(newValue) }}
                    onRequestSearch={onSubmit}
                    disabled={isLoading}
                  />
                </Col>
              </Row>
            </Container>
            <DiscoverGrid
              searchState={searchState}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              setHasNextPage={setHasNextPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              isMoreAdsActive={isMoreAdsActive}
              setIsMoreAdsActive={setIsMoreAdsActive}
            />
          </Stack>
        </div>
      </DiscoverContext.Provider>
    </CollectionsContext.Provider>
  );
}
export default Discover;
