import { ReactNode, useContext, useState } from "react";
import { Button, Carousel, Col, Container, Row, Stack } from "react-bootstrap";
import '../AdCard.css';
import ReactPlayer from "react-player";
import { AdCardType } from "../CollectionBody";
import postimageplaceholder from '../../assets/postimageplaceholder.jpg';
import { AdDownloadContext } from "../AdDownloadContext";


export function AdVideoImpl({
  getAssetUrl,
  light, 
  url, 

  secondary_light,
  secondary_url,

  width, 
  caption, 
  title, 
  link_description, 
  cta_text, 
  page_name, 
  is_small = false
}) {
  const [isError, setIsError] = useState(0);

  const lightElem = (
    <img src={light}
      onError={({currentTarget}) => {
          if (currentTarget.src === secondary_light) {
              //both bucket and source media returned error
              currentTarget.onerror = null;
              currentTarget.src = postimageplaceholder;
              return;
          }
          currentTarget.onerror = null; // prevents looping
          currentTarget.src=secondary_light ?? postimageplaceholder;
      }}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }}
    />
  );

  let videoElem = (
    <ReactPlayer
      light={lightElem}
      url={url}
      alt="attachment-video"
      width={width}
      controls={true}
      playsinline={true}
      playing={true}
      onError={() => {
        setIsError(1);}}
    />
  );
  getAssetUrl(url);
  if (isError === 1) {
    videoElem = (
      <ReactPlayer
        light={lightElem}
        url={secondary_url}
        alt="attachment-video"
        width={width}
        controls={true}
        playsinline={true}
        playing={true}
        onError={() => {setIsError(2);}}
      />
    );
    getAssetUrl(secondary_url);
  } else if (isError === 2) {
    videoElem = (
      <img src={postimageplaceholder}
        alt="attachment-video"
      />
    );
    getAssetUrl(null);
  }
 
  return (
    <>
      <div
        style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'center',
          maxHeight: '600px', width: '100%', objectFit: 'contain',
          background: '#fff'
        }}
      >
        {videoElem}
      </div>
      {caption != null ?
        <div style={{ background: 'rgba(0,0,0,0.05)' }}>
          <Container>
            <Row>
              <Col xs={7}>
                <Stack direction='vertical'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}>
                  <small style={{ fontSize: '8px' }}>
                    {caption}
                  </small>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <small style={{ fontSize: '8px', fontWeight: '600' }}>
                      {caption != null ? title : page_name}
                    </small>
                    <small style={{ fontSize: '8px' }} className="description-clamp">
                      {link_description}
                    </small>
                  </div>
                </Stack>
              </Col>
              <Col xs={5}>
                {cta_text != null ?
                  (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', justifyContent: 'flex-end', fontSize: is_small ? '16px' : undefined, padding: '8px' }}>
                    <Button variant="light" size={is_small ? 'sm' : undefined}>
                      <small>{cta_text}</small>
                    </Button>
                  </div>) : null
                }
              </Col>
            </Row>
          </Container>
        </div> :
        null}
    </>
  );
}

function AdVideoAttachment(card: AdCardType) {
  const { data } = useContext(AdDownloadContext);

  if (card.asset == null) {
    return null;
  }

  const props = {
    getAssetUrl: (url) => {
      if (url == null) {
        data?.adDownloadClosure.setAdDownloadData(null);
      } else {
        data?.adDownloadClosure.setAdDownloadData({
          format: 'video',
          media_urls: [url]
        });
      }
    },
    light: card.asset.videos[0].video_preview_image_url, 
    url: card.asset.videos[0].video_url,
    secondary_light: card.asset.videos[0].video_preview_image_url_secondary,
    secondary_url: card.asset.videos[0].video_url_secondary,
    width: 310, 
    caption: card.asset.caption, 
    title: card.asset.title, 
    link_description: card.asset.link_description, 
    cta_text: card.asset.cta_text, 
    page_name: card.pageName,
  };

  return (
    <div style={{
      overflow: 'hidden',
      borderStyle: 'solid',
      borderColor: '#f7f7f7',
      borderRadius: '8px'
    }}>
      <AdVideoImpl
        {...props}
      />
    </div>
  );
}

export default AdVideoAttachment;
