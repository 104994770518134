import React from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import logo from './assets/logo_footer.png';

const Footer = ({isSmallScreen}) => {
  return (
    <Container fluid style={{marginTop: '150px'}}>
        <hr
            style={{
            borderTop: '1px solid #DDDDDD',
            margin: '1rem 0',
            }}
        />
        <Row>
            <Col sm={6} className="text-center text-sm-left">
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '16px', marginTop: '24px'}}>
                    <Stack style={{marginLeft: isSmallScreen ? undefined : '48px'}}>
                        <img
                            src={logo}
                            width={32}
                            height={32}
                            alt="Roas Hunter footer logo"
                        />
                        <p className="lead" style={{marginBottom: '0px', width: isSmallScreen ? undefined : '250px'}}><small>Copyright © 2023 Roas Hunt - All Rights Reserved.</small></p>
                    </Stack>
                    <Stack style={{marginLeft: '32px', flexDirection: 'column', display: 'flex', justifyContent: 'space-around'}}>
                        <h6 style={{color: 'var(--secondary)'}}>About</h6>
                        <a style={{color: 'var(--secondary)'}} href="/terms"><small>Terms & Conditions</small></a>
                        <a style={{color: 'var(--secondary)'}} href="/privacy"><small>Privacy Policy</small></a>
                    </Stack>
                </div>
            </Col>
        </Row>
    </Container>
  );
};

export default Footer;