import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import LeftRail from './LeftRail';
import NavbarContainer from './NavbarContainer';
import Footer from './Footer';
import axios from 'axios';
import TipPage from './TipPage';
import Onboarding from './Onboarding';
import IntroVideo from './IntroVideo';
import { Col, Row, Stack } from 'react-bootstrap';
import icon from './assets/laptopicon.png';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Verification from './Verification';
import metadataJson from './metadata.json';
import ForgotPassword from './ForgotPassword';
import Collections from './collections/Collections';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Discover from './discover/Discover';
import ExtCollections from './collections/ExtCollections';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-M323HH35GG"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.screen.width < 576);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let component;
  if (isSmallScreen) {
    component = (
      <Stack style={{ display: 'flex', flexDirection: 'column', margin: '32px' }}>
        <img src={icon} style={{ margin: '32px', objectFit: 'contain' }} />
        <Row>
          <Col sm={7}>
            <div style={{textAlign: 'center'}}>
              <b>
                Roas Hunt is on Desktop for Now
              </b>
            </div>
            <div style={{ paddingTop: '8px', textAlign: 'center' }}>
              <p>Thank you for logging on mobile! For now, Roas Hunt is available on computer/laptop.
                Go to app.roashunt.com on your computer to get access to our full suite of features!</p>
            </div>
          </Col>
        </Row>
      </Stack>
    );
  } else {
    component = <Outlet />;
  }

  return (
    <GoogleOAuthProvider clientId={metadataJson.client_id}>
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <NavbarContainer isSmallScreen={isSmallScreen} />
          <div style={{ flexGrow: '1' }}>
              <Routes>
                <Route path="/" element={<PrivateRoute>{component}</PrivateRoute>}>
                  <Route index={true} element={<LeftRail fromIndex={true}><Collections /></LeftRail>} />
                  <Route path="collections" element={<LeftRail fromIndex={false}><Outlet /></LeftRail>}>
                    <Route path=":id" element={<Collections />} />
                    <Route path="" element={<Collections />} />
                  </Route>
                  <Route path="discover" element={<Discover />} />
                </Route>

                <Route path="/ext" element={<Outlet />}>
                  <Route path="collections" element={<Outlet />}>
                    <Route path=":id" element={<ExtCollections isSmallScreen={isSmallScreen} />} />
                  </Route>
                </Route>

                <Route path="/onboarding" element={<PrivateRoute>{ isSmallScreen ? component : <Onboarding /> }</PrivateRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verification/:id" element={<Verification endpoint="register" />} />
                <Route path="/newsletter_verification/:id" element={<Verification endpoint="newsletter_lead" />}/>
                <Route path="/forgot_password/:email/:token" element={<ForgotPassword />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
              </Routes>
          </div>
          <Footer isSmallScreen={isSmallScreen} />
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
