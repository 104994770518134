import { Timestamp } from '@google-cloud/firestore';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { CollectionsContext } from '../LeftRail';

function CreationModal(props) {
  const { collections, setCollections } = useContext(CollectionsContext);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [validationMsg, setValidationMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const { name } = formData;
    const nameRegex: RegExp = /^[A-Za-z0-9\s]+$/;
    const isValidEmail = nameRegex.test(name);
    if (!isValidEmail) {
      setValidationMsg('Invalid name format.');
      setIsLoading(false);
      return;
    }

    axios.post('/collection', formData)
    .then((response) => {
      const { data } = response;
      const obj = data.data;
      let arr = [ ...collections];
      arr.push({
        collection_id: obj.id,
        collection_name: obj.name,
        is_default: obj.is_default,
        creation_time: obj.creation_time
      });
      setCollections(arr);
      setIsLoading(false);
      props.onHide();
    })
    .catch((error) => {
      setIsLoading(false);
      setValidationMsg(error.response.data.message);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal
      {...props}
      aria-labelledby="collection-creation-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Collection
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {validationMsg != '' && <Alert key={'danger'} variant={'danger'}>
            {validationMsg}
          </Alert>}
          <h5>Name Your Collection</h5>
          <Form.Group className="mb-3" controlId="formBasicName" style={{ marginTop: '16px' }}>
            <Form.Control type="name" placeholder="" name='name' onChange={handleChange} disabled={isLoading} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={isLoading}>Submit</Button>
          <Button variant='secondary' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function CreateCollection() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant="secondary" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        onClick={() => setModalShow(true)}>
        Create Collection
      </Button>
      <CreationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default CreateCollection;