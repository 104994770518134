import { useContext, useEffect, useRef, useState } from "react";
import { ShimmerPostList } from "react-shimmer-effects";
import StackGrid, { transitions } from "react-stack-grid";
import AdCard from "../collections/AdCard";
import { DiscoverContext } from "./Discover";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import DefaultGrid from "./DefaultGrid";
import MoreAdsCard from "./MoreAdsCard";

const { fade } = transitions;

function DiscoverGrid({ isLoading, searchState, hasNextPage, setHasNextPage, pageNumber, setPageNumber, isMoreAdsActive, setIsMoreAdsActive }) {
  const grid = useRef<any>(null)
  const { cards, setCards } = useContext(DiscoverContext);
  const [paginationIsLoading, setPaginationIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (grid.current) {
        grid.current.updateLayout();
      }
    }, 1000);
  }, [isLoading, paginationIsLoading]);

  const loadMore = () => {
    setPaginationIsLoading(true);
    const number = pageNumber;
    axios.post('/search', { query: searchState, page: number + 1 }, { baseURL: 'https://roas-hunt-search-43n265a2nq-uc.a.run.app' })
      .then((response) => {
        const data = response.data;
        setPaginationIsLoading(false);
        if (data.cards == null || data.cards.length === 0) {
          setHasNextPage(false);
        }
        setCards([...cards, ...data.cards]);
      })
      .catch((error) => {
        console.log(error);
        setPaginationIsLoading(false);
        setHasNextPage(false);
      });
    setPageNumber(pageNumber + 1);
  }

  const commit = (collectionID, cardID, adArchiveID, inventoryCollectionName) => {
    axios.post('/discover/save', {
      collection_id: collectionID,
      card_id: cardID,
      ad_archive_id: adArchiveID,
      inventory_collection_name: inventoryCollectionName,
    })
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [sentryRef] = useInfiniteScroll({
    loading: paginationIsLoading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  if (isLoading) {
    return (
      <div style={{ margin: '32px' }}>
        <ShimmerPostList postStyle="STYLE_FOUR" col={4} row={2} gap={30} />
      </div>
    );
  }

  const moreAdsElem = <MoreAdsCard setIsMoreAdsActive={setIsMoreAdsActive} />;

  const renderedElements = cards.map(({ inventory_col_name, is_saved, card }, idx) => (
    <div key={`grid_div_${card.id}`} className="item" style={{ width: '100%' }}>
      <AdCard
        onMove={(collectionID) => {
          commit(
            collectionID,
            card.id,
            card.adArchiveID,
            inventory_col_name
          );
        }}
        onRemove={() => { }}
        isEditMode={false}
        isDiscoverMode={true}
        saved={is_saved}
        {...card}
      />
    </div>
  ));

  if (cards.length !== 0 && isMoreAdsActive) {
    renderedElements.splice(1, 0, moreAdsElem);
  }

  return (
    <>
      <div style={{ marginTop: '32px' }}>
        <StackGrid
          gridRef={r => (grid.current = r)}
          columnWidth={350}
          gutterWidth={24}
          gutterHeight={32}
          monitorImagesLoaded={true}
          appearDelay={30}
          appear={fade.appeared}
          appeared={fade.appeared}
          enter={fade.enter}
          entered={fade.entered}
          leaved={fade.leaved}
        >
          {renderedElements}
        </StackGrid>
        {(cards.length !== 0 && (paginationIsLoading || hasNextPage)) && (
          <div ref={sentryRef} style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '82px'
          }}>
            <Spinner animation="border" role="status" />
          </div>
        )}
      </div>
      {cards.length == 0 && <DefaultGrid />}
    </>
  );
}
export default DiscoverGrid;
