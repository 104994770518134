import React, { useEffect, useState } from 'react';
import { Alert, Card, Form, Stack } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ShimmerThumbnail } from "react-shimmer-effects";


function Verification({endpoint}) {
  const params = useParams();
  const token = params.id;

  const [msg, setMsg] = useState(null);
  
  useEffect(() => {
    axios.post(`/${endpoint}/verify`, {verification_token: token})
    .then((response) => {
      setMsg(response.data.message);
    })
    .catch((error) => {
      setMsg(error.response.data.message);
   });
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Stack style={{ width: '18rem', marginTop: '100px' }}>
        <Card className="text-center">
          <Card.Body>
            {msg == null ? <ShimmerThumbnail height={250} rounded /> : <>{msg}</>}
          </Card.Body>
        </Card>
      </Stack>
    </div>
  );
}

export default Verification;
