import { Timestamp } from '@google-cloud/firestore';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import CreateCollection from './collections/CreateCollection';
import CollectionTab from './CollectionTab';

export type CollectionData = {
    collection_id: string,
    is_default: boolean,
    collection_name: string,
    creation_time: Timestamp
};

export const CollectionsContext = React.createContext<{
    collections: CollectionData[],
    setCollections: React.Dispatch<React.SetStateAction<CollectionData[]>
    >
}>({ collections: [], setCollections: () => { } });

function LeftRail({ children, fromIndex = false }) {

    const [collections, setCollections] = useState<CollectionData[]>([]);

    return (
        <CollectionsContext.Provider value={{ collections, setCollections }}>
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '100vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '350px', borderRight: '1px solid #DDDDDD' }}>
                    <Stack style={{ gap: '16px', display: 'flex', flexDirection: 'column', padding: '28px' }}>
                        <div><CreateCollection /></div>
                        <div style={{ borderTop: '1px solid #d2d2d2' }} />
                        <div><CollectionTab fromIndex={fromIndex} /></div>
                    </Stack>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    {children}
                </div>
            </div>
        </CollectionsContext.Provider>
    );
}

export default LeftRail;
