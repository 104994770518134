import axios from 'axios';
import { Button, ButtonGroup, Card, Col, Container, Form, ListGroup, Row, Stack, Modal, DropdownButton, Dropdown, Tooltip, OverlayTrigger, CloseButton } from 'react-bootstrap';
import img from '../assets/relevantadsimg.png';

function MoreAdsCard({setIsMoreAdsActive}) {
  
  const onClose = () => {
    axios.post('/profile/agency_guide_upsell_dismissed', {})
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const commit = () => {
    axios.post('/profile/agency_guide_request', {})
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
        <Card style={{ width: '350px' }}>
          <div style={{display: 'flex', flexDirection:'row', width:'100%', justifyContent: 'end'}}>
            <CloseButton style={{marginRight: '20px', marginTop: '20px'}} 
              onClick={() => {
                onClose();
                setIsMoreAdsActive(false);
              }}/>
          </div>
          <Card.Img variant="top" src={img} height={100} style={{objectFit: 'contain'}} />
          <Card.Body>
            <Card.Title>Ads Relevant?</Card.Title>
            <Card.Text>
              Are these ads fitting your use case?<br/>  
              Getting value?<br/>
              If not, we'll make you a custom pipeline of creatives based on your preferences.
            </Card.Text>
            <Button variant="primary" onClick={() => {
              commit();
              setIsMoreAdsActive(false);
            }}>Connect with Us</Button>
          </Card.Body>
        </Card>
      
  );
}

export default MoreAdsCard;
